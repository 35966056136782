import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBan,
  faBookOpenCover,
  faBooks,
  faBullseyeArrow,
  faCaretRight,
  faChartLineDown,
  faChartLineUp,
  faCheck,
  faChevronDown,
  faCircleCheck,
  faCircleInfo,
  faCircleNodes,
  faClock,
  faClockRotateLeft,
  faCross,
  faDash,
  faDown,
  faDownload,
  faEye,
  faEyeSlash,
  faFileCertificate,
  faGauge,
  faHiking,
  faHyphen,
  faLayerPlus,
  faLink,
  faList,
  faLock,
  faLockA,
  faMedal,
  faMoneyCheckDollarPen,
  faPersonRunning,
  faQuestionCircle,
  faRotate,
  faScreenUsers,
  faSitemap,
  faSpinner,
  faSwap,
  faTriangleExclamation,
  faUp,
  faUserCheck,
  faUserHeadset,
  faUserSecret,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faArrowLeft as farArrowLeft,
  faArrowRight as farArrowRight,
  faArrowRotateLeft as farArrowRotateLeft,
  faBan as farBan,
  faBookOpenCover as farBookOpenCover,
  faBooks as farBooks,
  faCalendarPlus as farCalendarPlus,
  faChalkboardUser as farChalkboardUser,
  faChartLineDown as farChartLineDown,
  faChartLineUp as farChartLineUp,
  faChevronDown as farChevronDown,
  faChevronRight as farChevronRight,
  faCircle as farCircle,
  faCircleInfo as farCircleInfo,
  faCircleNodes as farCircleNodes,
  faCircleQuestion as farCircleQuestion,
  faCircleXmark as farCircleXmark,
  faClipboardListCheck as farClipboardListCheck,
  faClock as farClock,
  faClockRotateLeft as farClockRotateLeft,
  faCopy as farCopy,
  faCross as farCross,
  faDoorOpen as farDoorOpen,
  faEdit as farEdit,
  faEllipsisVertical as farEllipsisVertical,
  faEnvelope as farEnvelope,
  faFileCertificate as farFileCertificate,
  faFileImport as farFileImport,
  faGauge as farGauge,
  faHand as farHand,
  faHeadSideGoggles as farHeadSideGoggles,
  faHiking as farHiking,
  faKey as farKey,
  faKeySkeleton as farKeySkeleton,
  faLayerPlus as farLayerPlus,
  faLink as farLink,
  faList as farList,
  faListTimeline as farListTimeline,
  faLockKeyhole as farLockKeyhole,
  faMagnifyingGlass as farMagnifyingGlass,
  faMask as farMask,
  faMedal as farMedal,
  faMoneyCheckDollarPen as farMoneyCheckDollarPen,
  faPenToSquare as farPenToSquare,
  faPersonRunning as farPersonRunning,
  faPlus as farPlus,
  faPlusCircle as farPlusCircle,
  faPrint as farPrint,
  faQuestionCircle as farQuestionCircle,
  faRotate as farRotate,
  faScreenUsers as farScreenUsers,
  faSitemap as farSitemap,
  faSliders as farSliders,
  faSwap as farSwap,
  faTimes as farTimes,
  faTrashCan as farTrashCan,
  faTriangleExclamation as farTriangleExclamation,
  faUserGear as farUserGear,
  faUserHardHat as farUserHardHat,
  faUserHeadset as farUserHeadset,
  faUserLock as farUserLock,
  faUserPlus as farUserPlus,
  faUserXmark as farUserXmark,
  faUsers as farUsers,
  faUsersGear as farUsersGear,
  faXmark as farXmark,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faAlien8bit as falAlien8bit,
  faArrowLeft as falArrowLeft,
  faArrowsFromLine as falArrowsFromLine,
  faArrowsToLine as falArrowsToLine,
  faBan as falBan,
  faBookOpenCover as falBookOpenCover,
  faBooks as falBooks,
  faCamera as falCamera,
  faChalkboardUser as falChalkboardUser,
  faChartLineDown as falChartLineDown,
  faChartLineUp as falChartLineUp,
  faChevronDown as falChevronDown,
  faChevronRight as falChevronRight,
  faCircle as falCircle,
  faCircleInfo as falCircleInfo,
  faCircleNodes as falCircleNodes,
  faDownload as falDownload,
  faEdit as falEdit,
  faFaceLaughBeam as falFaceLaughBeam,
  faFileCertificate as falFileCertificate,
  faFileExport as falFileExport,
  faFilePdf as falFilePdf,
  faFilter as falFilter,
  faGauge as falGauge,
  faHand as falHand,
  faHeadSideGoggles as falHeadSideGoggles,
  faHiking as falHiking,
  faKey as falKey,
  faLink as falLink,
  faList as falList,
  faMagnifyingGlass as falMagnifyingGlass,
  faMedal as falMedal,
  faOtter as falOtter,
  faPaperPlane as falPaperPlane,
  faPersonChalkboard as falPersonChalkboard,
  faPersonRunning as falPersonRunning,
  faPlus as falPlus,
  faQuestionCircle as falQuestionCircle,
  faRobot as falRobot,
  faRotate as falRotate,
  faScreenUsers as falScreenUsers,
  faSignsPost as falSignsPosts,
  faSitemap as falSitemap,
  faSlashForward as falSlashForward,
  faSwap as falSwap,
  faTrashCan as falTrashCan,
  faUpload as falUpload,
  faUserGear as falUserGear,
  faUserHardHat as falUserHardHat,
  faUserHeadset as falUserHeadset,
  faUserLock as falUserLock,
  faUserPlus as falUserPlus,
  faUserXmark as falUserXmark,
  faUsers as falUsers,
  faUsersGear as falUsersGear,
  faXmark as falXmark,
} from "@fortawesome/pro-light-svg-icons";

import { faHyphen as fatHyphen } from "@fortawesome/pro-thin-svg-icons";

export function initIcons() {
  // Add solid style icons
  library.add(
    faSitemap,
    faGauge,
    faUsers,
    faScreenUsers,
    faFileCertificate,
    faBookOpenCover,
    faUserHeadset,
    faTriangleExclamation,
    faCheck,
    faLock,
    faUserCheck,
    faDash,
    faCaretRight,
    faCircleCheck,
    faMedal,
    faBullseyeArrow,
    faQuestionCircle,
    faCircleInfo,
    faEye,
    faEyeSlash,
    faTriangleExclamation,
    faSpinner,
    faLockA,
    faCircleNodes,
    faDownload,
    faHyphen,
    faBooks,
    faUserSecret,
    faPersonRunning,
    faList,
    faLayerPlus,
    faUp,
    faDown,
    faClock,
    faClockRotateLeft,
    faSwap,
    faChevronDown,
    faRotate,
    faChartLineUp,
    faChartLineDown,
    faBan,
    faHiking,
    faLink,
    faCross,
    faMoneyCheckDollarPen
  );

  // Add regular style icons
  library.add(
    farSitemap,
    farGauge,
    farUsers,
    farScreenUsers,
    farFileCertificate,
    farBookOpenCover,
    farUserHeadset,
    farArrowLeft,
    farArrowRight,
    farChevronRight,
    farXmark,
    farPenToSquare,
    farTrashCan,
    farMagnifyingGlass,
    farCircleQuestion,
    farCircleInfo,
    farCalendarPlus,
    farQuestionCircle,
    farTimes,
    farDoorOpen,
    farUserPlus,
    farUsersGear,
    farUserLock,
    farLockKeyhole,
    farEdit,
    farUserGear,
    farChalkboardUser,
    farHeadSideGoggles,
    farPlus,
    farEllipsisVertical,
    farKey,
    farKeySkeleton,
    farCircleNodes,
    farMask,
    farBooks,
    farUserXmark,
    farCircle,
    farSliders,
    farCircleXmark,
    farPersonRunning,
    farList,
    farUserHardHat,
    farMedal,
    farHand,
    farEnvelope,
    farClock,
    farListTimeline,
    farClockRotateLeft,
    farLayerPlus,
    farSwap,
    farChevronDown,
    farRotate,
    farChartLineUp,
    farChartLineDown,
    farBan,
    farHiking,
    farTriangleExclamation,
    farLink,
    farCopy,
    farCross,
    farClipboardListCheck,
    farPlusCircle,
    farFileImport,
    farPrint,
    farMoneyCheckDollarPen
  );

  // Add light style icons
  library.add(
    falSitemap,
    falGauge,
    falUsers,
    falScreenUsers,
    falFileCertificate,
    falBookOpenCover,
    falUserHeadset,
    falArrowLeft,
    falChevronRight,
    falXmark,
    falCircleInfo,
    falFaceLaughBeam,
    falAlien8bit,
    falRobot,
    falOtter,
    falFilter,
    falXmark,
    falSignsPosts,
    falUserXmark,
    falFileExport,
    falFilePdf,
    falCircle,
    falSlashForward,
    falMagnifyingGlass,
    falTrashCan,
    falChalkboardUser,
    falPersonChalkboard,
    falUserPlus,
    falUpload,
    falDownload,
    falPlus,
    falHeadSideGoggles,
    falUsersGear,
    falUserLock,
    falUserGear,
    falEdit,
    falCircleNodes,
    falKey,
    falPaperPlane,
    falBooks,
    falQuestionCircle,
    falHand,
    falMedal,
    falPersonRunning,
    falArrowsFromLine,
    falArrowsToLine,
    falCamera,
    falList,
    falUserHardHat,
    falSwap,
    falChevronDown,
    falRotate,
    falChartLineUp,
    falChartLineDown,
    falBan,
    falHiking,
    falLink,
    farArrowRotateLeft
  );

  // Add thin style icons
  library.add(fatHyphen);
}
